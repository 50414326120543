<template>
  <div class="vue-box">
    <div class="c-panel">
      <!-- 参数栏 -->
      <div class="c-title">支付宝收款账号配置</div>
      <el-form :inline="true" size="mini">
        <el-form-item v-if="this.sa_admin.role == 2" label="码队选择:">
          <el-select size="mini" v-model="p.mchId" @click.native="getMchCode" @change="f5">
            <el-option label="全部" :value="null"></el-option>
            <el-option v-for="item in selectList" :key="item.mchId" :label="item.merchantNickName" :value="item.mchId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-plus" v-if="this.sa_admin.role == 3" size="mini" @click="add()">
            添加商户
          </el-button>
          <el-button type="success" icon="el-icon-refresh" @click="
              p.page = 1;
              f5();
            ">刷新
          </el-button>
        </el-form-item>
      </el-form>
      <el-table :row-class-name="tableRowClassName" :data="dataList" :key="conditionsKey"
                :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }" size="mini">
        <el-table-column label="id" prop="id" width="50px"></el-table-column>
        <el-table-column label="所属码队" prop="mchId" min-width="60px">
          <template slot-scope="s">
            <b>{{ processingMchId(s.row.mchId) }}</b>
          </template>
        </el-table-column>
        <el-table-column label="商户名" prop="mchName" min-width="100px">
        </el-table-column>
        <el-table-column label="应用appId" prop="appid" width="130px" :formatter="remarksAppid"></el-table-column>
        <el-table-column label="账号类型" prop="type" width="100px">
          <template slot-scope="s">
            <b style="color: green; margin-left: 10px" v-if="s.row.type == 1">直付通服务商</b>
            <b style="color: blue; margin-left: 10px" v-if="s.row.type == 2">直连商户</b>
            <b style="color: blue; margin-left: 10px" v-if="s.row.type == 3">扫码授权</b>
          </template>
        </el-table-column>
        <el-table-column label="收款类型" prop="type" width="110px">
          <template slot-scope="s">
            <b style="color: green; margin-left: 10px" v-if="s.row.payType == 1">预授权</b>
            <b style="color: blue; margin-left: 10px" v-if="s.row.payType == 2">商家扣款</b>
            <b style="color: blue; margin-left: 10px" v-if="s.row.payType == 3">当面付</b>
            <b style="color: blue; margin-left: 10px" v-if="s.row.payType == 4">单笔转账(系统)</b>
            <b style="color: blue; margin-left: 10px" v-if="s.row.payType == 5">手机网站</b>
            <b style="color: blue; margin-left: 10px" v-if="s.row.payType == 6">个码收款</b>
            <b style="color: blue; margin-left: 10px" v-if="s.row.payType == 7">手机网站-游戏</b>
            <b style="color: blue; margin-left: 10px" v-if="s.row.payType == 8">手机网站-商城</b>
            <b style="color: blue; margin-left: 10px" v-if="s.row.payType == 9">订单码支付</b>
            <b style="color: blue; margin-left: 10px" v-if="s.row.payType == 25">当面付-游戏网站</b>
            <b style="color: blue; margin-left: 10px" v-if="s.row.payType == 26">当面付-商城网站</b>
            <b style="color: blue; margin-left: 10px" v-if="s.row.payType == 27">黄金-个码收款</b>
            <b style="color: blue; margin-left: 10px" v-if="s.row.payType == 28">支付宝APP支付</b>
            <b style="color: blue; margin-left: 10px" v-if="s.row.payType == 29">订单码支付-商城</b>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="120px">
          <template slot-scope="s">
            <el-switch :value="s.row.status" :active-value="1" :inactive-value="0" inactive-color="#ff4949"
                       @change="setStatus(s.row)"></el-switch>
            <b style="color: green; margin-left: 10px" v-if="s.row.status == 1">启用</b>
            <b style="color: red; margin-left: 10px" v-if="s.row.status == 0">禁用</b>
          </template>
        </el-table-column>
        <el-table-column label="昨日收入" min-width="90px">
          <template slot-scope="s">
            <el-link style="color: green; margin-right: 5px; font-size: 14px">{{
                s.row.zrsr
              }}
            </el-link>
            <el-link icon="el-icon-refresh" style="font-size: 16px" @click="yesterDayRefreshFee(s.row)"></el-link>
          </template>
        </el-table-column>
        <el-table-column label="今日收入" min-width="90px">
          <template slot-scope="s">
            <el-link style="color: green; margin-right: 5px; font-size: 14px">{{
                s.row.jrsr
              }}
            </el-link>
            <el-link icon="el-icon-refresh" style="font-size: 16px" @click="toDayRefreshFee(s.row)"></el-link>
          </template>
        </el-table-column>
        <el-table-column label="限额" min-width="70px">
          <template slot-scope="s">
            <b>￥{{ s.row.limits }}</b>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="主子模式" min-width="60px">
          <template slot-scope="s">
            <div v-if="s.row.sonAppidStatus == 1">
              <b style="color: green; margin-left: 10px">是</b><br>
              <b style="color: #2b88d8; margin-left: 10px;text-decoration: underline;cursor: pointer;"
                 @click="showSonAppidStatistics(s.row)">收款统计</b>
            </div>
            <b style="color: #a19f9d; margin-left: 10px" v-if="s.row.sonAppidStatus == 0">否</b>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="备注" prop="remarks" :formatter="remarksFormat" min-width="150px">
        </el-table-column>
        <el-table-column show-overflow-tooltip label="是否分账" min-width="60px">
          <template slot-scope="s">
            <b style="color: green; margin-left: 10px" v-if="s.row.profitShare == 1">开启分账</b>
            <b style="color: #a19f9d; margin-left: 10px" v-if="s.row.profitShare == 0">不分账</b>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="是否配置优惠券" min-width="60px">
          <template slot-scope="s">
            <i :style="s.row.couponQrcode ? 'color: red;' : 'color: #a19f9d;'" class="el-icon-s-ticket"></i>
            <b style="color: red; " v-if="s.row.couponQrcode ">是</b>
            <b style="color: #a19f9d;" v-if="!s.row.couponQrcode">否</b>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" width="150px" fixed="right">
          <template slot-scope="s">
            <el-button class="c-btn" type="success" icon="el-icon-edit" @click="update(s.row)">编辑</el-button>
            <el-dropdown style="margin-left: 5px;">
              <span class="el-dropdown-link">
                <el-button class="c-btn" type="primary" icon="el-icon-more">更多</el-button>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-if="s.row.type ==3" icon="el-icon-link" @click.native="pageSign(s.row)">网页授权
                </el-dropdown-item>
                <el-dropdown-item v-if="s.row.type ==3" icon="el-icon-link" @click.native="showScImage(s.row)">
                  扫码授权
                </el-dropdown-item>
                <el-dropdown-item icon="el-icon-link" @click.native="tc(s.row)">提取</el-dropdown-item>
                <el-dropdown-item icon="el-icon-delete" @click.native="del(s.row)">删除</el-dropdown-item>
                <el-dropdown-item icon="el-icon-edit" @click.native="upsertCoupon(s.row)">配置优惠券二维码
                </el-dropdown-item>
                <el-dropdown-item icon="el-icon-link" v-if="sa_admin.role == 2" @click.native="autoChecked(s.row)">订单自动查补
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <ServerAdd ref="alipay-server-add"></ServerAdd>
      <AddAlipayCouponConfig ref="alipay-coupon-config"></AddAlipayCouponConfig>
      <!-- 分页 -->
      <div class="page-box">
        <el-pagination background layout="total, prev, pager, next, sizes, jumper" :current-page.sync="p.page"
                       :page-size.sync="p.limit" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]"
                       @current-change="f5(true)" @size-change="f5(true)">
        </el-pagination>
      </div>
      <el-dialog top="6vh" :append-to-body="true" title="子号收入统计" :visible.sync="showSonAppidStatisticsVisible"
                 min-size="48%" size="48%" direction="rtl">
        <div class="vue-box" style="background-color:#ffffff;">
          <el-descriptions v-for="(item, index) in sonAppidStatistics" :key="index" :column="3" border>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-user"></i>
                子号UID
              </template>
              {{ item.appid }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                昨日收入
              </template>
              {{ item.zrsr }}
              <el-link icon="el-icon-refresh" style="font-size: 16px"
                       @click="sonAppYesterDayRefreshFee(index)"></el-link>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                今日收入
              </template>
              {{ item.jrsr }}
              <el-link icon="el-icon-refresh" style="font-size: 16px" @click="sonAppToDayRefreshFee(index)"></el-link>
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="showSonAppidStatisticsVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import ServerAdd from '../server-provider/alipay-server-add'
import AddAlipayCouponConfig from '@/sa-view/server-provider/add-alipay-coupon-config.vue'

export default {
  components: { ServerAdd, AddAlipayCouponConfig },
  data () {
    return {
      p: {
        page: 1,
        type: [2, 3],
        payType: [1, 2, 3, 4, 5, 6, 7, 8, 9, 25, 26, 27, 28, 29],
        limit: 10,
      },
      title: '添加商户',
      userDialogVisible: false,
      dataCount: 0,
      conditionsKey: 0,
      selectList: [],
      dataList: [],
      showSonAppidStatisticsVisible: false,
      sonAppidStatistics: [],
    }
  },
  methods: {

    // 数据刷新
    f5: function () {
      this.getMchCode()
      if (this.sa_admin.role == 2 || this.sa_admin.role == 3) {
        var defaultCfg = {
          isBody: true, //是否是请求体请求
        }
        if (this.sa_admin.role == 3) {
          this.p.mchId = this.sa_admin.mchId
        }
        this.sa.ajax(
          '/v1/alipay/config/query',
          this.p,
          function (res) {
            this.dataList = res.data.records
            this.dataCount = res.data.total
          }.bind(this),
          defaultCfg,
        )
      } else {
        defaultCfg = {
          type: 'get',
        }
        this.sa.ajax(
          '/v1/alipay/config/query',
          this.p,
          function (res) {
            this.dataList = res.data.records
            this.dataCount = res.data.total
          }.bind(this),
          defaultCfg,
        )
      }

    },
    upsertCoupon: function (data) {
      this.$refs['alipay-coupon-config'].open(data)
    },
    // 删除
    del: function (data) {
      var defaultCfg = {
        type: 'delete', //是否是请求体请求
      }
      this.sa.ajax(
        '/v1/alipay/config/delete/' + data.id,
        function () {
          this.f5()
        }.bind(this), defaultCfg,
      )
    },
    autoChecked: function (data) {
      var defaultCfg = {
        type: 'get', //是否是请求体请求
      }
      this.sa.ajax(
        '/v1/alipay/config/autoChecked?appid=' + data.appid,
        function (res) {
          this.sa.ok(res.msg);
        }.bind(this), defaultCfg,
      )
    },
    processingMchId (mchId) {
      const merchant = this.selectList.find(item => item.mchId === mchId)
      return merchant ? merchant.merchantNickName : null
    },
    tableRowClassName ({ row, rowIndex }) {
      //把每一行的索引放进row
      row.index = rowIndex
    },
    setStatus: function (data) {
      this.sa.confirm(
        '是否要修改收款商户状态',
        function () {
          var defaultCfg = {
            type: 'get', //是否是请求体请求
          }
          var p = {
            status: data.status, //是否是请求体请求
          }
          var params = data
          this.sa.ajax(
            '/v1/alipay/config/setStatus/' + data.id, p,
            function () {
              this.sa.ok('修改成功')
              params.status = params.status == 1 ? 0 : 1
            }.bind(this), defaultCfg,
          )
        }.bind(this),
      )
    },
    showSonAppidStatistics: function (data) {
      console.log(data)
      this.sonAppidStatistics = []
      if (data.sonAppidStatus === 0) {
        return
      }
      data.sonAppid.forEach((item) => {
        this.sonAppidStatistics.push({ mchId: data.mchId, pAppid: data.appid, appid: item.appid, zrsr: '', jrsr: '' })
      })
      this.showSonAppidStatisticsVisible = true
    },
    sonAppToDayRefreshFee: function (index) {
      //获取金额
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      }
      const { mchId, pAppid, appid } = this.sonAppidStatistics[index]
      var p2 = {
        mchId,
        pappid: pAppid,
        appid,
      }
      console.log('aaa', p2)
      this.sa.ajax(
        '/v1/dataAnalysis/sonAppIdRealtimeAmountToday',
        p2,
        function (res) {
          console.log(res)
          this.sonAppidStatistics[index].jrsr = '￥' + res.data
        }.bind(this),
        defaultCfg,
      )
    },
    sonAppYesterDayRefreshFee: function (index) {
      //获取金额
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      }
      const { mchId, pAppid, appid } = this.sonAppidStatistics[index]
      var p2 = {
        mchId,
        pappid: pAppid,
        appid,
      }
      this.sa.ajax(
        '/v1/dataAnalysis/sonAppIdRealtimeAmountYesterday',
        p2,
        function (res) {
          console.log(res)
          this.sonAppidStatistics[index].zrsr = '￥' + res.data
        }.bind(this),
        defaultCfg,
      )
    },

    yesterDayRefreshFee: function (data) {
      console.log(data)
      //获取金额
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      }
      var p2 = {
        wechatMch: data.appid, //是否是请求体请求
      }
      this.sa.ajax(
        '/v1/dataAnalysis/realtimeAmountYesterday',
        p2,
        function (res) {
          data.zrsr = '￥' + res.data
          this.$set(this.dataList, data.index, data)
          this.conditionsKey++
        }.bind(this),
        defaultCfg,
      )
    },
    tc: function (data) {
      let self = this
      this.sa.prompt(
        '输入创建订单的金额',
        function (value) {
          var p1 = {
            id: data.id,
            amount: value,
            payType: data.payType,
          }
          var defaultCfg = {
            type: 'get',
          }
          self.sa.ajax(
            '/v1/alipay/config/preTest',
            p1,
            function (res) {
              self.sa.showImageWithText(
                this.sa.cfg.api_url +
                '/v1/index/enQrcode?url=' +
                encodeURIComponent(res.data),
                '600px',
                '330px', res.data,
              )
            }.bind(self),
            defaultCfg,
          )
        },
        0,
      )
    },
    getMchCode: function () {
      var defaultCfg = {
        type: 'get', //是否是请求体请求
        sleep: 0,
        msg: null,
      }
      this.sa.ajax(
        '/v2/admin/queryMerchantNickName/3',
        function (res) {
          this.selectList = res.data // 数据
        }.bind(this),
        defaultCfg,
      )
    },
    remarksFormat (row) {
      if (row.remarks == '' || row.remarks == null) {
        return this.getCloseMsgFormat(row) + '无备注'
      } else {
        return this.getCloseMsgFormat(row) + row.remarks
      }
    },
    getCloseMsgFormat (row) {
      if (row.closeMsg == '' || row.closeMsg == null) {
        return ''
      } else {
        return row.closeMsg
      }
    },
    profitShareFormat (row) {
      if (row.profitShare == 1) {
        return '开启分账'
      } else {
        return '不分账'
      }
    },
    remarksAppid (row) {
      if (row.appid == '' || row.appid == null) {
        return '未获取到Appid'
      } else {
        return row.appid
      }
    },
    toDayRefreshFee: function (data) {
      //获取金额
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      }
      var p2 = {
        wechatMch: data.appid, //是否是请求体请求
        codeMchId: data.mchId,
      }
      this.sa.ajax(
        '/v1/dataAnalysis/realtimeAmountToDay',
        p2,
        function (res) {
          data.jrsr = '￥' + res.data
          this.$set(this.dataList, data.index, data)
          this.conditionsKey++
        }.bind(this),
        defaultCfg,
      )
    },
    add: function () {
      this.$refs['alipay-server-add'].open(0, 2)
    },
    update: function (data) {
      this.$refs['alipay-server-add'].open(data, 2)
    },
    pageSign: function (data) {
      const bizDataObj = {
        platformCode: 'O',
        taskType: 'INTERFACE_AUTH',
        agentOpParam: {
          redirectUri: 'http://gateway.zyigc.cn/bindAlipay',
          appTypes: ['TINYAPP', 'WEBAPP', 'PUBLICAPP'],
          isvAppId: '2021004184632010',
          state: btoa(data.id),
        },
      }
      const bizData = encodeURIComponent(JSON.stringify(bizDataObj))
      const link = `https://b.alipay.com/page/message/tasksDetail?bizData=${bizData}`
      window.open(link)
    },
    showScImage (data) {
      const bizDataObj = {
        platformCode: 'O',
        taskType: 'INTERFACE_AUTH',
        agentOpParam: {
          redirectUri: 'http://gateway.zyigc.cn/bindAlipay',
          appTypes: ['TINYAPP', 'WEBAPP', 'PUBLICAPP'],
          isvAppId: '2021004184632010',
          state: btoa(data.id),
        },
      }
      const bizData = encodeURIComponent(JSON.stringify(bizDataObj))
      const qrcode = `alipays://platformapi/startapp?appId=2021003130652097&page=pages%2Fauthorize%2Findex%3FbizData%3D${bizData}`
      this.sa.showImage(
        this.sa.cfg.api_url +
        '/v1/index/enQrcode?url=' +
        encodeURIComponent(qrcode),
        '250px',
        '250px',
      )
    },
  },
  created: function () {
    this.f5()
  },
}
</script>

<style>
</style>
